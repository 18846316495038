.project-card-hover-text {
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
}
.project-card:hover .project-card-hover-text {
  top: 50%;
  opacity: 1;
}
