
@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  scroll-behavior: smooth;
  @apply bg-gray-700 scroll-pt-topBar;
}

*::-webkit-scrollbar {
  width: 0.4em !important;
  height: 0.4em !important;

}

*::-webkit-scrollbar-thumb {
  @apply bg-gray-800 rounded-md;
}
